<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" routerLink="/accueil" routerLinkActive="active" [src]='logoSophie' alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Administration</h2>
      <hr/>
    </div>



<clr-tabs>
  <clr-tab>
      <button clrTabLink id="newsButtonTab">Actualités</button>
      <clr-tab-content id="news" *clrIfActive>
        <div class="pageTitle2">
          <h2>Nouvelle actualité</h2>
        </div>
        <form clrForm [formGroup]="createNewsForm" (ngSubmit)="submitCreateNews()">
          <clr-input-container>
              <label>Titre</label>
              <input clrInput type="text" formControlName="title"/>
          </clr-input-container>
          <clr-date-container>
            <label>Date</label>
            <input type="date" formControlName="date" clrDate>
          </clr-date-container>

          <div class="editor">
            <quill-editor
            formControlName="description"
            [styles]="{height: '200px'}"
            ></quill-editor>
          </div>
          <button class="btn btn-primary" type="submit">Créer l'actualité</button>
        </form>

        <div class="pageTitle2">
          <h2>Toutes les actualités</h2>
        </div>
        <div>
          <div *ngFor="let new of news.slice().reverse(), let i = index, let last = last" class="news-card">

            <div class="title-date-news">
              <div class="news-title" *ngIf="new.title">
                {{ new.title}}
              </div>
              <div class="news-date" *ngIf="new.date">
                {{ new.date }}
              </div>
            </div>
            <div class="news-text" *ngIf="new.description" [innerHTML]="new.description">
              {{new.description}}
            </div>
            <div class="news-button">
              <button type="button" class="btn btn-icon btn-primary-outline" aria-label="edit" (click)="OpenUpdateNewsModal(new, news.length-1-i)">
                <clr-icon shape="pencil" class="is-solid"></clr-icon>
              </button>
              <button type="button" class="btn btn-icon btn-danger-outline" aria-label="delete" (click)="OpenDeleteNewsModal(new, news.length-1-i)">
                <clr-icon shape="trash" class="is-solid"></clr-icon>
              </button>
          </div>

            <hr *ngIf="!last"/>
          </div>
        </div>

        <!-- delete modal -->
        <clr-modal [(clrModalOpen)]="deleteNewsModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">Supprimer une actualité</h3>
          <div class="modal-body">
              <h2>Actualité à supprimer:</h2>
              <div class="card-header" *ngIf="newsToDelete.date">
                {{ newsToDelete.date }}
            </div>
            <div class="card-block">
                <div class="card-title" *ngIf="newsToDelete.title">
                    {{ newsToDelete.title}}
                </div>
                <div class="card-text" *ngIf="newsToDelete.description" [innerHTML]="newsToDelete.description">
                    {{newsToDelete.description}}
                </div>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline" (click)="closeDeleteNewsModal()">Annuler</button>
              <button type="button" class="btn btn-primary" (click)="deleteNews()">Supprimer</button>
          </div>
        </clr-modal>
        <!-- delete modal end -->

        <!-- update modal -->
        <clr-modal [(clrModalOpen)]="updateNewsModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">Modifier une actualité</h3>
          <div class="modal-body">
            <!-- <h2>Modifier:</h2> -->
            <form clrForm [formGroup]="updateNewsForm" (ngSubmit)="submitUpdateNews()">
              <clr-input-container>
                  <label>Titre</label>
                  <input clrInput type="text" formControlName="title"/>
              </clr-input-container>
              <clr-date-container>
                <label>Date</label>
                <input type="date" formControlName="date" clrDate>
              </clr-date-container>
              <div class="editor">
                <quill-editor
                formControlName="description"
                [styles]="{height: '200px'}"
                ></quill-editor>
              </div>
            </form>
            <!-- <h2>Aperçu:</h2>
            <div class="card">
              <div class="card-header" *ngIf="newsToUpdate.date">
                {{ createNewsForm.value.date }}
              </div>
              <div class="card-block">
                  <div class="card-title" *ngIf="newsToUpdate.title">
                    {{ createNewsForm.value.title }}
                  </div>
                  <div class="card-text" *ngIf="newsToUpdate.description">
                      {{ createNewsForm.value.description}}
                  </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline" (click)="closeUpdateNewsModal()">Fermer</button>
              <button type="button" class="btn btn-primary" (click)="submitUpdateNews()">Modifier</button>
          </div>
        </clr-modal>
        <!-- end update modal -->

      </clr-tab-content>
  </clr-tab>
  <clr-tab>
      <button clrTabLink id="eventsButtonTab">Stages</button>
      <clr-tab-content id="events" *clrIfActive>
        <div class="pageTitle2">
          <h2>Nouveau stage</h2>
        </div>
        <form clrForm [formGroup]="createEventsForm" (ngSubmit)="submitCreateEvents()">
          <clr-input-container>
            <label>Titre</label>
            <input clrInput type="text" formControlName="title" />
          </clr-input-container>
          <clr-date-container>
            <label>Date</label>
            <input type="date" formControlName="date" clrDate>
          </clr-date-container>
          <clr-input-container>
            <label>Tarif</label>
            <input clrInput type="text" formControlName="tarif" />
          </clr-input-container>
          <clr-input-container>
            <label>Lieu</label>
            <input clrInput type="text" formControlName="lieu" />
          </clr-input-container>
          <clr-input-container>
            <label>Ville</label>
            <input clrInput type="text" formControlName="ville"/>
          </clr-input-container>
          <div class="editor">
            <quill-editor
            formControlName="description"
            [styles]="{height: '200px'}"
            ></quill-editor>
          </div>
          <button class="btn btn-primary" type="submit">Créer le stage</button>
        </form>

        <div class="pageTitle2">
          <h2>Tous les Stages</h2>
        </div>
        <div>
          <div *ngFor="let event of events.slice().reverse(), let i = index, let last = last" class="events-card">

            <div class="title-ville-events">
              <div class="events-title" *ngIf="event.title">
                {{ event.title}}
              </div>
              <div class="events-ville" *ngIf="event.ville">
                {{ event.ville }}
              </div>
            </div>
            <div class="events-text" *ngIf="event.description" [innerHTML]="event.description">
              {{event.description}}
            </div>
            <div class="tarif-date-lieu">
              <div class="events-date-lieu">
                <div class="events.date" *ngIf="event.date">
                  {{ event.date }}
                </div>
                <div class="events-lieu" *ngIf="event.lieu">
                  {{event.lieu}}
                </div>
              </div>
              <div class="events-tarif" *ngIf="event.tarif">
                {{event.tarif}}
              </div>
            </div>

            <div class="events-button">
              <button type="button" class="btn btn-icon btn-primary-outline" aria-label="edit" (click)="OpenUpdateEventsModal(event, events.length-1-i)">
                <clr-icon shape="pencil" class="is-solid"></clr-icon>
              </button>
              <button type="button" class="btn btn-icon btn-danger-outline" aria-label="delete" (click)="OpenDeleteEventsModal(event, events.length-1-i)">
                <clr-icon shape="trash" class="is-solid"></clr-icon>
              </button>
          </div>

            <hr *ngIf="!last"/>
          </div>
        </div>


        <!-- delete modal -->
        <clr-modal [(clrModalOpen)]="deleteEventsModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">Supprimer un stage</h3>
          <div class="modal-body">
              <h2>Stage à supprimer:</h2>
              <div class="card-header" *ngIf="eventsToDelete.date">
                {{ eventsToDelete.date }}
              </div>
              <div class="card-text" *ngIf="eventsToDelete.description">
                {{eventsToDelete.ville}}
              </div>
              <div class="card-block">
                <div class="card-title" *ngIf="eventsToDelete.title">
                    {{ eventsToDelete.title}}
                </div>
                <div class="card-text" *ngIf="eventsToDelete.description" [innerHTML]="eventsToDelete.description">
                    {{eventsToDelete.description}}
                </div>
                <div class="card-text" *ngIf="eventsToDelete.description">
                  {{eventsToDelete.tarif}}
              </div>
              <div class="card-text" *ngIf="eventsToDelete.description">
                {{eventsToDelete.lieu}}
              </div>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline" (click)="closeDeleteEventsModal()">Annuler</button>
              <button type="button" class="btn btn-primary" (click)="deleteEvents()">Supprimer</button>
          </div>
        </clr-modal>
        <!-- delete modal end -->

        <!-- update modal -->
        <clr-modal [(clrModalOpen)]="updateEventsModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">Modifier un stage</h3>
          <div class="modal-body">
            <!-- <h2>Modifier:</h2> -->
            <form clrForm [formGroup]="updateEventsForm" (ngSubmit)="submitUpdateEvents()">
              <clr-input-container>
                <label>Titre</label>
                <input clrInput type="text" formControlName="title" />
              </clr-input-container>
              <clr-date-container>
                <label>Date</label>
                <input type="date" formControlName="date" clrDate>
              </clr-date-container>
              <clr-input-container>
                <label>Tarif</label>
                <input clrInput type="text" formControlName="tarif" />
              </clr-input-container>
              <clr-input-container>
                <label>Lieu</label>
                <input clrInput type="text" formControlName="lieu" />
              </clr-input-container>
              <clr-input-container>
                <label>Ville</label>
                <input clrInput type="text" formControlName="ville"/>
              </clr-input-container>
              <div class="editor">
                <quill-editor
                formControlName="description"
                [styles]="{height: '200px'}"
                ></quill-editor>
              </div>
            </form>
            <!-- <h2>Aperçu:</h2>
            <div class="card">
              <div class="card-header" *ngIf="newsToUpdate.date">
                {{ createNewsForm.value.date }}
              </div>
              <div class="card-block">
                  <div class="card-title" *ngIf="newsToUpdate.title">
                    {{ createNewsForm.value.title }}
                  </div>
                  <div class="card-text" *ngIf="newsToUpdate.description">
                      {{ createNewsForm.value.description}}
                  </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline" (click)="closeUpdateEventsModal()">Fermer</button>
              <button type="button" class="btn btn-primary" (click)="submitUpdateEvents()">Modifier</button>
          </div>
        </clr-modal>
        <!-- end update modal -->

      </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Cours</button>
    <clr-tab-content id="lessons" *clrIfActive>
     <h2>Les cours</h2>
    </clr-tab-content>
</clr-tab>
</clr-tabs>
</div>
</div>
