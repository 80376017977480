import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  news: any[] = [];
  newsSubject = new Subject<any[]>();

  constructor() { }

  saveNews() {
    firebase.database().ref('/news').set(this.news);
  }

  // READ ///////////
  emitNews() {
    this.newsSubject.next(this.news);
  }

  getNews() {
    firebase.database().ref('/news').on('value', (data) => {
      this.news = data.val() ? data.val() : [];
      this.emitNews();
    });
  }

    // CREATE ///////////
  createNews(newNews: any) {
    this.news.push(newNews);
    this.saveNews();
    this.emitNews();
  }

  // DELETE ///////////
  removeNews(news: any, id: number) {
    this.news.splice(id, 1);
    this.saveNews();
    this.emitNews();
  }

  // UPDATE ///////////
  updateNews(news, id: number) {
    firebase.database().ref('/news/' + id).update(news);
  }

}
