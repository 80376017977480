import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NewsService } from 'src/app/services/news.service';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit, OnDestroy {

  logoSophie = '../../assets/logoHome.png';

  // editor param ///////////////////////

  // news variable //////////////////////
  news: any[] = [];
  newsSubscription: Subscription;
  deleteNewsModal: boolean = false;
  newsToDelete: any = {};
  newsIdToDelete: number;
  createNewsForm: FormGroup;

  updateNewsModal: boolean = false;
  newsToUpdate: any = {};
  newsIdToUpdate: number;
  updateNewsForm: FormGroup;

  // events variable ////////////////////
  events: any[] = [];
  eventsSubscription: Subscription;
  deleteEventsModal: boolean = false;
  eventsToDelete: any = {};
  eventsIdToDelete: number;
  createEventsForm: FormGroup;

  updateEventsModal: boolean = false;
  eventsToUpdate: any = {};
  eventsIdToUpdate: number;
  updateEventsForm: FormGroup;

  // lessons variable ///////////////////
  lessons: [] = [];

  constructor(
    private newsService: NewsService,
    private eventsService: EventsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.initCreateNewsForm();
    this.initUpdateNewsForm();
    this.initCreateEventsForm();
    this.initUpdateEventsForm();
    this.subscribeNews();
    this.subscribeEvents();
    this.newsService.getNews();
    this.eventsService.getEvents();
    // this.newsService.emitNews();
  }

  ngOnDestroy(): void {
    this.newsSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

  // editor ///////////////////////

  // news ///////////////////////////////////////////////
  // READ
  subscribeNews() {
    this.newsSubscription = this.newsService.newsSubject.subscribe(
      (news: any[]) => {
        this.news = news;
      }
    );
  }

  // CREATE :
  initCreateNewsForm() {
    this.createNewsForm = this.formBuilder.group({
      title: '',
      description: '',
      date: ''
    });
  }

  submitCreateNews() {
    const formValue = this.createNewsForm.value;
    this.newsService.createNews(formValue);
    this.initCreateNewsForm();
  }

  // UPDATE :
  OpenUpdateNewsModal(newsToUpdate: any, i: number) {
    this.newsToUpdate = newsToUpdate;
    this.newsIdToUpdate = i;
    this.updateNewsModal = true;
    this.initUpdateNewsForm();
  }

  closeUpdateNewsModal() {
    this.newsToUpdate = {};
    this.newsIdToUpdate = null;
    this.updateNewsModal = false;
  }

  submitUpdateNews() {
    const formValue = this.updateNewsForm.value;
    this.newsService.updateNews(formValue, this.newsIdToUpdate);
    this.closeUpdateNewsModal();
  }

  initUpdateNewsForm() {
    if (Object.keys(this.newsToUpdate).length === 0 ) {
      this.updateNewsForm = this.formBuilder.group({
        title: '',
        description: '',
        date: ''
      });
    } else {
      this.updateNewsForm = this.formBuilder.group({
        title: this.newsToUpdate.title,
        description: this.newsToUpdate.description,
        date: this.newsToUpdate.date
      });
    }

  }

  // DELETE
  OpenDeleteNewsModal(newsToDelete: any, id: number) {
    this.newsToDelete = newsToDelete;
    this.deleteNewsModal = true;
    this.newsIdToDelete = id;
  }

  closeDeleteNewsModal() {
    this.newsToDelete = {};
    this.deleteNewsModal = false;
    this.newsIdToDelete = null;
  }

  deleteNews() {
    this.newsService.removeNews(this.newsToDelete, this.newsIdToDelete);
    this.closeDeleteNewsModal();
  }

  // events ///////////////////////////////////////////////
  // READ :
  subscribeEvents() {
    this.eventsSubscription = this.eventsService.eventsSubject.subscribe(
      (events: any[]) => {
        this.events = events;
      }
    );
  }

  // CREATE :
  initCreateEventsForm() {
    this.createEventsForm = this.formBuilder.group({
      title: '',
      description: '',
      date: '',
      tarif: '',
      lieu: '',
      ville: '',
    });
  }

  submitCreateEvents() {
    const formValue = this.createEventsForm.value;
    this.eventsService.createEvents(formValue);
    this.initCreateEventsForm();
  }
  // UPDATE :

  OpenUpdateEventsModal(eventsToUpdate: any, i: number) {
    this.eventsToUpdate = eventsToUpdate;
    this.eventsIdToUpdate = i;
    this.updateEventsModal = true;
    this.initUpdateEventsForm();
  }

  closeUpdateEventsModal() {
    this.eventsToUpdate = {};
    this.eventsIdToUpdate = null;
    this.updateEventsModal = false;
  }

  submitUpdateEvents() {
    const formValue = this.updateEventsForm.value;
    this.eventsService.updateEvents(formValue, this.eventsIdToUpdate);
    this.closeUpdateEventsModal();
  }

  initUpdateEventsForm() {
    if (Object.keys(this.eventsToUpdate).length === 0 ) {
      this.updateEventsForm = this.formBuilder.group({
        title: '',
        description: '',
        date: '',
        tarif: '',
        lieu: '',
        ville: '',
      });
    } else {
      this.updateEventsForm = this.formBuilder.group({
        title: this.eventsToUpdate.title,
        description: this.eventsToUpdate.description,
        date: this.eventsToUpdate.date,
        tarif: this.eventsToUpdate.tarif,
        lieu: this.eventsToUpdate.lieu,
        ville: this.eventsToUpdate.ville,
      });
    }

  }

  // DELETE :

  OpenDeleteEventsModal(eventsToDelete: any, id: number) {
    this.eventsToDelete = eventsToDelete;
    this.deleteEventsModal = true;
    this.eventsIdToDelete = id;
  }

  closeDeleteEventsModal() {
    this.eventsToDelete = {};
    this.deleteEventsModal = false;
    this.eventsIdToDelete = null;
  }

  deleteEvents() {
    this.eventsService.removeEvents(this.eventsToDelete, this.eventsIdToDelete);
    this.closeDeleteEventsModal();
  }

  // lessons /////////////////////////////////////////////// DON't KNOW HOW TO DO YET
  // READ : TODO
  // CREATE : TODO
  // UPDATE : TODO
  // DELETE : TODO

}
