import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  events: any[] = [];
  eventsSubject = new Subject<any[]>();

  constructor() { }

  saveEvents() {
    firebase.database().ref('/events').set(this.events);
  }


  // READ ///////////
  emitEvents() {
    this.eventsSubject.next(this.events);
  }

  getEvents() {
    firebase.database().ref('/events').on('value', (data) => {
      this.events = data.val() ? data.val() : [];
      this.emitEvents();
    });
  }

  // CREATE ///////////
  createEvents(event: any) {
    this.events.push(event);
    this.saveEvents();
    this.emitEvents();
  }

  // DELETE ///////////
  removeEvents(events: any, id: number) {
    this.events.splice(id, 1);
    this.saveEvents();
    this.emitEvents();
  }

  // UPDATE ///////////
  updateEvents(events, id: number) {
    firebase.database().ref('/events/' + id).update(events);
  }

}
