<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div>
      <div class="moshe-block1">

          <div class="card moshe-img1">
            <img [src]="moshePortrait" alt="Photo de moshé feldenkrais" />
          </div>

        <div class="moshe-text">
          <p>
            <span class="highlight">Moshé Feldenkrais</span> est né en 1904 en
            Russie.<br />
            Adolescent, il part en Palestine où il restera une dizaine d’année. <span
              class="highlight"
            >
            Sportif de haut niveau, il y apprend puis enseigne le Ju-Jutsu</span
            >, technique japonaise  ancestrale de combat et d'autodéfense,
            qui allie souplesse et techniques d'évitement pour contrôler et
            canaliser la force adverse, sans s'opposer frontalement.
          </p>
          <p>
            Dans les années trente, afin de poursuivre ses études scientifiques, il s'installe en France où il étudiera sous la direction de
            <span class="highlight"
              >Frédéric Joliot-Curie et Irène Curie (prix Nobel de Chimie, 1935),</span
            > et de <span class="highlight"
            >Paul Langevin</span
          > (éminent physicien, chercheur, philosophe, co-inventeur du sonar). Plus tard, il obtiendra un
          <span class="highlight"
            > doctorat ès Sciences Physiques.</span
          >
          </p>
          <p>
            Dans ces années là, il rencontre <span class="highlight">
              Jigoro Kano, fondateur de l'école de Judo</span
            > à Paris, et sa pratique du judo lui permettra d'obtenir en 1936,
             l'une des premières ceintures noires de Judo en Europe; peu après
             il participe à la création du premier Club de Ju-Jutsu de Paris.
          </p>
          <p>
            C’est dans les années quarante que son genou plusieurs fois blessé,
            lui pose de graves problèmes : la chirurgie qu'on lui propose n'est pas prometteuse,
            avec sa rigueur de scientifique il se met à l'étude du
            <span class="highlight"
              >corps d'un point de vue biomécanique,</span
            >
            approfondit et développe sa connaissance du mouvement déjà acquise à travers le judo et le Ju-Jutsu.
          </p>
        </div>
      </div>

      <div class="moshe-block2">
        <div class="moshe-text">
          <p>
            <span class="highlight"
              >Les arts martiaux lui ont appris l'utilisation d'un minimum d'effort pour un maximum d'efficacité.</span
            >
             Il va s'intéresser à l'évolution de l'Espèce, et grâce à son épouse pédiatre,
            <span class="highlight"> au développement moteur de l'enfant</span> ainsi qu'à des travaux de précurseurs tels que
            <span class="highlight"
              >Giurdjieff </span
            > ou
            <span class="highlight"
              >Mathias Alexander</span
            ><br />
            Dans un deuxième temps
            <span class="highlight">il étudie les Neurosciences</span>,
             balbutiantes à l’époque, mais qui vont lui ouvrir tout un champ des possibles en préssentant ce qui est confirmé aujourd’hui :
            <span class="highlight"
              > entre autres la neuroplasticité et la capacité du cerveau à apprendre tout au long de la vie.</span
            >
          </p>
          <p>
            <span class="highlight"
              >Des techniques comme le yoga ou le zen attirent aussi son attention.</span
            >
          </p>
          <p>
            A travers ces études approfondies,
            <span class="highlight"
              > il observe que sa capacité à bouger sans douleur dépend grandement de sa façon d’agir, </span
            >
            et que celle-ci peut être réajustée grâce à un processus simple.
          </p>
          <p>
            <span class="highlight"
              >Il va récupérer l'usage de son genou, de cette expérience va naître sa Méthode.</span
            > Il consacrera les quarantes dernières années de sa vie à la développer et à l’approfondir.
            <span class="highlight"
              >Ses travaux sont considérés encore aujourd'hui, comme précurseurs dans de nombreux domaines : éducation, santé, arts...</span
            >
          </p>
          <p>
            Il a enseigné en Europe, en Israël, en Australie et aux Etats-Unis et a formé des praticiens capables à leur tour de transmettre sa méthode et de poursuivre ses recherches.
          </p>
        </div>
          <div class="card  moshe-img2">
            <img [src]="mosheEnfant" class="img1" alt="Photo de moshé feldenkrais" />
          </div>

      </div>
    </div>
  </div>
</div>
