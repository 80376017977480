import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isLoggedIn: boolean = false;
  feldenkraisLogo: string = '../../assets/logo-feldenfrais.png';

  constructor(
    private authService: AuthentificationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged(userSession => {
      userSession ? this.isLoggedIn = true : this.isLoggedIn = false;
    });
  }

  onSignOut() {
    this.authService.signout();
    this.router.navigate(['/home']);
  }

}
