import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  logoSophie = '../../assets/logoHome.png';
  font = 'assets/fonts/keshyaff/Keshya.ttf';

  methodPicture = '../../../assets/methodePicture.jpg';
  integrationPicture = '../../../assets/integrationPicture.jpg';
  consciencePicture = '../../../assets/consciencePicture.jpg';
  news: [] = [];

  hightlightNews = '';

  constructor(
    private newsService: NewsService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    // this.getNews();
  }

  // getNews() {
  //   this.newsService.getNews().then((data: []) => {
  //     this.news = data;
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }

  scrollToContent() {
    this.viewportScroller.scrollToAnchor('presentation');
  }

}
