import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MethodComponent } from './components/method/method.component';
import { EventsComponent } from './components/events/events.component';
import { NewsComponent } from './components/news/news.component';
import { PlanningComponent } from './components/planning/planning.component';
import { ContactComponent } from './components/contact/contact.component';
import { AdminComponent } from './components/admin/admin.component';
import { SigninComponent } from './components/signin/signin.component';
import { AuthGuardService } from './guard/auth-guard.service';
import { MosheComponent } from './components/moshe/moshe.component';
import { SophieAubineauComponent } from './components/sophie-aubineau/sophie-aubineau.component';




const routes: Routes = [
  {
    path: 'accueil',
    component: HomeComponent
  },
  {
    path: 'la-methode',
    component: MethodComponent
  },
  {
    path: 'moshe-feldenkrais',
    component: MosheComponent
  },
  {
    path: 'sophie-aubineau',
    component: SophieAubineauComponent
  },
  {
    path: 'actualités',
    component: NewsComponent
  },
  {
    path: 'cours',
    component: PlanningComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    component: AdminComponent
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'accueil'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
