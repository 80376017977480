<app-header></app-header>
<div class="backgroundHomePage">
  <div class="light-bg">
    <div class="logo-title">
      <!-- <div class="title"> -->
      <img class="headerLogo" routerLink="/accueil" routerLinkActive="active" [src]='logoSophie' alt="logo Sophie Aubineau">
      <h2>La Méthode Feldenkrais<sup>TM</sup></h2>
      <!-- </div> -->
    </div>

    <div class="quote">
      <div class="testimonial-quote group">
        <div class="quote-container">
            <blockquote>
                <p>"Je crois que se connaître soi même
                  est la chose la plus importante qu'un être humain puisse faire pour lui-même."</p>
            </blockquote>
            <cite class="belgiansignatureFont">
              Moshé Feldenkrais
            </cite>
        </div>
    </div>
  </div>
      <button id="presentation" type="button" class="btn btn-icon btn-inverse scroll-button" aria-label="home" (click)="scrollToContent()">
        <clr-icon shape="angle-double"></clr-icon>
      </button>
  </div>
</div>
<div id="webContent">
  <div *ngIf="hightlightNews" class="hightlightNews"> <!-- -->
    <h3> </h3>  <!-- {{ hightlightNews }} -->
  </div>

  <div class="pageTitle">
    <h2>La Méthode Feldenkrais<sup>TM</sup></h2>
    <hr/>
  </div>

  <!-- <div class="homePageText"> -->
    <p>
      <span class="highlight">FELDEN-QUOI ?</span> Tout d’abord une petite aide à la prononciation : <span class="highlight">FEL-DEUNNN-KRA-ï-SSSS</span>.
    </p>
    <p>
    </p>
    <p class="card-text">
      Ce petit problème de prononciation résolu, de quoi s'agit-il ?<br/>
      La Méthode Feldenkrais<sup>TM</sup> développée par <a routerLink="/moshe-feldenkrais" routerLinkActive="active" class="link">Moshé Feldenkrais</a>, docteur en Sciences Physiques, nous enseigne comment <span class="highlight">bouger de façon fluide, harmonieuse, sans sensation d’effort.</span>
      La méthode en incluant la pensée, la perception, le mouvement, l’imagination, la prise de conscience,
         <span class="highlight">va toucher tous les aspects de notre être.</span> <!-- <span class="TODO">Lire la suite</span> TODO -->
         Elle se présente sous <span class="highlight">deux facettes complémentaires </span> menant au même processus  de développement.
    </p>
  <!-- </div> -->

  <div class="cards-wrapper">
    <!-- <div class="method-card">
        <div class="card"> TODO : link <a href="..." class="card clickable">
            <div class="card-img">
                <img [src]='methodPicture'>
            </div>
            <div class="card-block">
                <h2>La Méthode Feldenkrais<sup>TM</sup></h2>
                <p class="card-text">
                  La 'Méthode Feldenkrais' développée par <a routerLink="/moshe-feldenkrais" routerLinkActive="active" class="link">Moshé Feldenkrais</a>, docteur en Sciences Physiques, nous enseigne comment <span class="highlight">bouger de façon fluide, harmonieuse, sans sensation d’effort.</span>
                  La méthode en incluant la pensée, la perception, le mouvement, l’imagination, la prise de conscience,
                     <span class="highlight">va toucher tous les aspects de notre être.</span> <span class="TODO">Lire la suite</span> TODO
                     Elle se présente sous <span class="highlight">deux facettes complémentaires </span> menant au même processus  de développement.
                </p>
            </div>
          </div>
    </div> -->
    <div class="type-card">
        <div class="card"> <!-- TODO : link <a href="..." class="card clickable">-->
            <div class="card-img">
                <img [src]='consciencePicture'>
            </div>
            <div class="card-block">
              <h2>Intégration Fonctionnelle<sup>TM</sup></h2>
              <p class="card-text">
                C’est la version individuelle de la pratique,
                <span class="highlight">elle permet un accompagnement au plus près de vos besoins.</span>
                  La praticienne <span class="highlight">par un toucher fin et précis</span>, met à votre service son expérience du mouvement.
                  Elle ne va pas se centrer sur une zone qui pose problème mais réintégrer cette zone dans un ensemble.
                  <span class="highlight">Elle va chercher à mettre en lien les différents segments du corps pour un bon fonctionnement de l'ensemble, </span>
                  une meilleure aisance et efficacité du mouvement.
                  <!-- <span class="TODO">Lire la suite</span> -->
              </p>
          </div>
        </div>
    </div>
    <div class="type-card">
        <div class="card"> <!-- TODO : link <a href="..." class="card clickable"> -->
            <div class="card-img">
                <img [src]='integrationPicture'>
            </div>

            <div class="card-block">
              <h2>Prise de Conscience <br/>par le Mouvement<sup>TM</sup></h2>
              <p class="card-text">
                Cette version de la pratique est généralement proposée en groupe. Vous serez allongé au sol, assis ou debout et vous serez
                <span class="highlight">guidé verbalement par la praticienne</span>
                 afin d’explorer un processus allant du plus petit mouvement, vers un mouvement qui
                 <span class="highlight">de lui même devient ample, harmonieux et agréable.</span>
                 Vous serez invité à réduire le rythme et l’amplitude de vos gestes afin <span class="highlight">d’affiner votre sensibilité</span>
                   et d’ouvrir votre attention à l’ensemble de vous-même. <!--<span class="TODO">Lire la suite</span> -->
              </p>
          </div>
        </div>
    </div>
  </div>

  <div class="student-wrapper">
    <div class="student">
        <p class="quotationMark">&#8220;</p>
        <p class="text">
          "La philosophie qui sous-tend la pratique du Feldenkrais est très agréable puisqu'elle est bienveillante, dans le refus de la douleur et de l'effort, toute tournée vers la découverte et le plaisir.
Philosophie contagieuse pour le reste de notre vie !"
        </p>
        <p class="name">Mathieu</p>
    </div>
    <div class="student">
      <p class="quotationMark">&#8220;</p>
      <p class="text">
        "C'est génial la méthode Feldenkrais !<br>
        En plus de me sentir fraîche, légère de l'intérieur, et bien je me sens alignée comme à la sortie d'une séance d'ostéopathie, à chaque fin de séance.
        Franchement, c'est dingue la mécanique de notre petit corps !"
      </p>
      <p class="name">Zoé</p>
  </div>
  <div class="student">
    <p class="quotationMark">&#8220;</p>
    <p class="text">
      "Pendant l'heure que dure la séance je suis en dialogue avec mon corps, j’en respecte les limites et je découvre ou redécouvre ses nombreuses possibilités. 
      Tout ceci me re-pose, c'est précieux et vital ! En plus d'être simple et sans effort."
    </p>
    <p class="name">Pilar</p>
  </div>
  </div>

</div>


<!-- <h2> Toutes les actualités</h2>

<div *ngFor="let new of news" class="clr-row">
  <div class="clr-col-lg-5 clr-col-md-8 clr-col-12">
      <div class="card">
          <div class="card-header" *ngIf="new.date">
              {{ new.date }}
          </div>
          <div class="card-block">
              <div class="card-title" *ngIf="new.title">
                  {{ new.title}}
              </div>
              <div class="card-text" *ngIf="new.description">
                  {{new.description}}
              </div>
          </div>
      </div>
  </div>
</div> -->
