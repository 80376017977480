
<clr-header class="header header-1">
    <div class="header-nav" [clr-nav-level]="1">
      <div class="branding" routerLink="/accueil" routerLinkActive="active">
        <img class="headerLogo" [src]='logoSophie' alt="logo Sophie Aubineau">
        <h2>Sophie Aubineau</h2>
      </div>
      <div class="header-nav">
        <a routerLink="/accueil" fragment="presentation" routerLinkActive="active" class="nav-link nav-text">Accueil</a>
        <!-- <clr-dropdown *ngIf="innerWidth > 769">
          <a class="nav-link nav-text dropdownHeader" routerLinkActive="active" clrDropdownTrigger>
              <div>La méthode </div>
              <div class="iconDropdown">

                <clr-icon shape="caret down"></clr-icon>
              </div>
          </a>
          <clr-dropdown-menu *clrIfOpen>
            <a routerLink="/method" routerLinkActive="active" class="nav-link nav-text" clrDropdownItem >La méthode</a>
            <a routerLink="/moshe-feldenkrais" routerLinkActive="active" class="nav-link nav-text" clrDropdownItem >Moshé Feldenkrais</a>
          </clr-dropdown-menu>
        </clr-dropdown> -->
        <!-- <a routerLink="/la-methode" routerLinkActive="active" class="nav-link nav-text methodItem" *ngIf="innerWidth < 770">La méthode</a> -->
        <!-- <a routerLink="/moshe-feldenkrais" routerLinkActive="active" class="nav-link nav-text methodItem" *ngIf="innerWidth < 770" >Moshé Feldenkrais</a> -->


        <a routerLink="/moshe-feldenkrais" routerLinkActive="active" class="nav-link nav-text" >Moshé Feldenkrais</a>
        <a routerLink="/actualités" routerLinkActive="active" class="nav-link nav-text">Actualités</a>
        <a routerLink="/cours" routerLinkActive="active" class="nav-link nav-text">Agenda</a>
        <a routerLink="/sophie-aubineau" routerLinkActive="active" class="nav-link nav-text">Qui suis-je ?</a>
        <a routerLink="/contact" routerLinkActive="active" class="nav-link nav-text">Me contacter</a>
        <a routerLink="/admin" routerLinkActive="active" class="nav-link nav-text" *ngIf="isLoggedIn">Admin</a>
      </div>
      <!-- <div class="header-actions">
          <a href="javascript://" class="nav-link nav-icon" aria-label="settings">
              <clr-icon shape="cog"></clr-icon>
          </a>
      </div> -->
    </div>
</clr-header>


