<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="signin-wrapper">
      <form class="login" [formGroup]="signinForm" (ngSubmit)="submitSignin()">
          <section class="title">
              <!-- <h2>Bonjour Sophie</h2> -->
              <h2>Bonjour</h2>
          </section>
          <div class="login-group">
              <clr-input-container>
                  <label>Email</label>
                  <input type="text" clrInput formControlName="email" id='email'/>
              </clr-input-container>
              <clr-password-container>
                  <label >Mot de passe</label>
                  <input type="password" clrPassword formControlName="password" id='password'/>
              </clr-password-container>
              <!-- <clr-checkbox-wrapper>
                  <label>Remember me</label>
                  <input type="checkbox" name="rememberMe" clrCheckbox [(ngModel)]="signinForm.rememberMe"/>
              </clr-checkbox-wrapper> -->
              <div class="error active">
                  Invalid user name or password
              </div>
              <button type="submit" class="btn btn-primary">Connexion</button>
          </div>
      </form>
    </div>
  </div>
</div>
