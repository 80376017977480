<clr-main-container>

    <router-outlet>

    </router-outlet>
    <footer>

      <app-footer id="footer"></app-footer>
    </footer>
</clr-main-container>

