import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  logoSophie = '../../assets/logoHome.png';

  signinForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.initSigninForm();
  }

  initSigninForm() {
    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  submitSignin() {
    const signinFormValue = this.signinForm.value;
    this.authService.singin(signinFormValue).then((data) => {
      this.router.navigate(['/admin']);
    }).catch((error) => {
      console.log(error);
    })
  }

}
