<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active"
      alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Planning des cours 2023-2024</h2>
      <hr />
    </div>
    <h3 class="subTitle">
      Pour les inscriptions et renseignements, <a routerLink="/contact" routerLinkActive="active" class="link">me
        contacter</a>.
    </h3>
    <div>
      <div class="clr-row">
        <div class="clr-col-lg-4 clr-col-12 cardContainer">
          <div class="card">
            <div class="card-header">
              Toulouse
            </div>
            <div class="card-block">
              <div class="card-text">
                <div class="day">
                  Mercredi
                </div>
                <div class="hour">
                  <div>
                    17h15-18h15
                  </div>
                  <div class="detail">
                    « ma bulle en santé » 95 bis Allées Charles de Fitte
                  </div>
                </div>
              </div>
              <div class="card-text">
                <div class="day">
                  Vendredi
                </div>
                <div class="hour">
                  <div>
                    12h30-13h30
                  </div>
                  <div class="detail">
                    la cartoucherie, salle des quatre vents, allées de l’EFT
                  </div>
                </div>
              </div>
              <div class="adress">
                <div>
                  Tarif à l’année : 300 €
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-col-lg-4 clr-col-12 cardContainer">
          <div class="card">
            <div class="card-header">
              Ramonville
            </div>
            <div class="card-block">
              <div class="card-text">
                <div class="day">
                  Mardi
                </div>
                <div class="hour">
                  <div>
                    9h30-10h30
                  </div>
                  <div class="detail">
                    Salle "le studio", 33, rue des Ormes
                  </div>
                  <div>
                    11h15-12h15
                  </div>
                  <div class="detail">
                    Salle Picasso, place Picasso
                  </div>
                  <div>
                    14h30-15h30
                  </div>
                  <div class="detail">
                    Salle Picasso, place Picasso
                  </div>
                </div>
              </div>
              <div class="card-text">
                <div class="day">
                  jeudi
                </div>
                <div class="hour">
                  <div>
                    19h15-20h15
                  </div>
                  <div class="detail">
                    Salle Picasso, place Picasso
                  </div>
                </div>
              </div>
              <div class="adress">
                <div>
                  Tarif à l’année : 255 €
                  <!-- <a href="https://www.visavie-ramonville.fr/pratiques/feldenkrais-tm/" class="link" target="_blank"
                    rel="noopener" rel="noreferrer">https://www.visavie-ramonville.fr/pratiques/feldenkrais-tm/</a> -->
                </div>
              </div>
              <div class="adress">
                <div>
                  Tarif : 50 € les cinq séances
                </div>
              </div>
            </div>
            <!-- <div class="card-header">
              Tarifs
            </div> -->
            <!-- <div class="card-block">
              <div class="card-text">
                <div class="place">
                  Cours à Ramonville
                </div>
                <p class="price">
                  Engagement à l'année 246 €
                  <br />
                  125 € la carte de 10 cours -->
            <!-- <br/>
                  140 € la carte de 10 séances -->
            <!-- <br /> -->
            <!-- Cours d’essai gratuit -->
            <!-- Vous êtes invité à un cours d'essai
                </p>
              </div> -->
            <!-- </div> -->
          </div>
        </div>

        <div class="clr-col-lg-4 clr-col-12 cardContainer">
          <div class="card">
            <div class="card-header">
              Cours en visio-conférence
            </div>
            <div class="card-block">
              <div class="card-text">
                <div class="day">
                  Vendredi
                </div>
                <div class="hour">
                  <div>
                    10h30-11h30
                  </div>
                  <div class="detail">
                    Tarif à l’année : 300 €
                  </div>
                </div>
              </div>

              <!-- <div class="adress">
                <div>
                  Tarif pour 10 cours 100 €
                </div>
              </div> -->
            </div>
            <!-- <div class="card-header">
              Tarifs
            </div> -->
            <!-- <div class="card-block">
              <div class="card-text">
                <p class="price">
                  60€ un cours par jour, 80€ les deux.
                  <br />
                  Si vous voulez être informés des prochaines dates, envoyez moi un mail je vous tiendrais informés.
                </p>
              </div>
            </div> -->
          </div>
        </div>

        <div class="clr-col-lg-4 clr-col-12 cardContainer">
          <div class="card">
            <div class="card-header">
              Séances collectives sur demande
            </div>
            <div class="card-block">
              <div class="card-text-center">
                <div class="hour">
                  <div class="detail-less-margin">
                    Vous êtes un groupe de musiciens, danseurs, marcheurs, étudiants, collègues...
                    <br />
                    Enfin vous faite des choses ensemble, je vous propose des ateliers adaptés à vos besoins.
                    <br />
                    Nous construisons ensemble le projet : besoins spécifiques, durée, lieu...Je peux me déplacer dans
                    d'autres régions.
                    <br />
                    Contactez moi et demandez...
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <!-- <div class="hightlightNews"> -->
        <!--*ngIf="hightlightNews" -->
        <!-- <h3> Cours assurés en visio conférence en cas de fermeture des salles.</h3> {{ hightlightNews }} -->
      <!-- </div> -->

    </div>

    <div class="pageTitle">
      <h2>Séance individuelle d'Intégration Fonctionnelle<sup>TM</sup></h2>
      <hr />
    </div>
    <h3 class="subTitle">
      Sur rendez-vous à Toulouse 19 rue Julien Sacaze 31100 ou à domicile en cas d’impossibilité de déplacement et sous
      certaines conditions.
      <p>
        Tarifs: 60€ pour les adultes et 45€ pour les enfants.
      </p>
    </h3>
    <div class="pageTitle">
      <h2>Les ateliers et stages 2023-2024</h2>
      <hr />
    </div>
    <!-- <h3 class="subTitle">
      Atelier à thèmes un samedi matin par mois.
      <p>A Ramonville, Place Picasso, Salle Picasso entre entre 10h et 12h.
        <br/>
        Tarif: 25€ par atelier.
      </p>
    </h3> -->
    <!-- <div class="hightlightNews"> -->
      <!--*ngIf="hightlightNews" -->
      <!-- <h3> Ateliers assurés en visio conférence en cas de fermeture des salles pour cause sanitaire.</h3> -->
      <!-- {{ hightlightNews }} -->
    <!-- </div> -->

    <!-- <div class="atelier-card">
      <div class="theme-atelier">
        Pratiques quotidiennes :
      </div>
      <div class="atelier-date">
        <p>
          Première série :
        </p>
        <p>
          Du 30 Août Au 04 Septembre<br>
          de 8h à 9h et de 18h30 19h30<br>
          La même leçon sera donnée le matin et le soir<br>
          Vous pouvez vous inscrire pour un cours par jour ou pour les deux<br>
        </p>
        <p>
          Tarifs<br>
          60 € un cours par jour, 80 les deux.
        </p>
        <p>
          Si vous voulez être informés des prochaines dates, envoyez moi un mail je vous tiendrais informés.
        </p>
      </div>
      <hr />
    </div> -->


    <div>
      <div class="atelier-card">
        <div class="theme-atelier">
          Ateliers mensuels du samedi 10h-12h
        </div>
        <div class="atelier-date">
          <div>
            Salle à coté du métro, Ramonville, mail Françoise Dolto
            <p class="bold">La main et le bras</p>
            7 octobre, 11 novembre, 9 décembre
            <p class="bold">Thème à définir</p>
            13 janvier, 10 février, 9 mars
            <p class="bold">Thème à définir</p>
            27 avril, 25 mai (ATTENTION horaire 14h30 - 16h30), 22 juin
            Tarif : 25 € par atelier
          </div>
        </div>
        <hr />
      </div>

      <div class="atelier-card">
        <div class="theme-atelier">
          Ateliers à Mirepoix (09)
        </div>
        <div class="atelier-date">
          <div>
            Du samedi 25 novembre 15h au dimanche 26 novembre 13h
            <!-- <br />
            16,17,18 décembre
            <br />
            17,18,19 février
            <br />
            14,15,16 Avril
            <br />
            18,19,20,21 mai -->
            <!-- <p class="bold">Les thèmes et horaires vous seront communiqués au fur et à mesure.</p> -->
          </div>
        </div>
        <hr />
      </div>

    </div>


    <!-- <div class="pageTitle">
      <h2>Feldenkrais et Yoga</h2>
      <hr />
    </div> -->
    <!-- <h3 class="subTitle">
      Avec Magali San Christobal, professeure de yoga. Une journée par trimestre pour explorer les ponts entre ces
      deux pratiques complémentaires.
      <p>A Ramonville, place Picasso, salle Picasso entre 9h30 et 17h.
        <br />
        Tarif: 65€/personne.
      </p>
    </h3> -->

    <!-- <div>
      <div class="atelier-card">
        <div class="theme-atelier">
          La colonne vertébrale dans ses trois dimensions
        </div>
        <div class="atelier-date">
          <p>
            26 septembre 2020
          </p>
        </div>
        <hr />
      </div>
      <div class="atelier-card">
        <div class="theme-atelier">
          Trouver ses appuis pour plus de stabilité et de fluidité
        </div>
        <div class="atelier-date">
          <p>
            13 mars 2021
          </p>
        </div>
        <hr />
      </div>
      <div class="atelier-card">
        <div class="theme-atelier">
          La ceinture scapulaire et l’ouverture de l’espace du coeur
        </div>
        <div class="atelier-date">
          <p>
            9 mai 2021
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="pageTitle">
      <h2>Les autres stages</h2>
      <hr />
    </div> -->

    <div>
      <div *ngFor="let event of events.slice().reverse(), let i = index, let last = last" class="events-card">

        <div class="title-ville-events">
          <div class="events-title" *ngIf="event.title">
            {{ event.title}}
          </div>
          <div class="events-ville" *ngIf="event.ville">
            {{ event.ville }}
          </div>
        </div>
        <div class="events-text" *ngIf="event.description" [innerHTML]="event.description">
          {{event.description}}
        </div>
        <div class="tarif-date-lieu">
          <div class="events-date-lieu">
            <div class="events.date" *ngIf="event.date">
              {{ event.date }}
            </div>
            <div class="events-lieu" *ngIf="event.lieu">
              {{event.lieu}}
            </div>
          </div>
          <div class="events-tarif" *ngIf="event.tarif">
            {{event.tarif}}
          </div>
        </div>

        <hr *ngIf="!last" />
      </div>
    </div>

  </div>
</div>
