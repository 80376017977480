<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Me contacter</h2>
      <hr/>
    </div>
    <h3 class="subTitle contactInfo">
      <div>Par téléphone :</div>
      <div>06 30 134 109</div>
    </h3>
    <h3 class="subTitle contactInfo">
      <div>Par courriel à l'adesse :</div>
      <div>sophie.aubineau.feld@protonmail.com</div>
    </h3>

  </div>
</div>
