<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Qui suis-je ?</h2>
      <hr/>
    </div>
    <div>
      <div class="card imgSophie">
        <img [src]='imgSophie' alt="Sophie Aubineau">
      </div>
      <p>
        <span class="highlight">
          Enseignante de la Méthode Feldenkrais<sup>TM</sup> depuis 2002, j'interviens auprès d’un public varié, musiciens, danseurs, personnes en situation de handicap, enfants, parents / bébés, seniors…
        </span>
      </p>
      <p>
        Très jeune, je me suis intéressée à diverses approches corporelles ou psycho-corporelles,
        <span class="highlight">
          au Yoga, à la danse, au massage, au Rebirth, à la méditation...
        </span>
        J’ai redécouvert à travers ces pratiques le plaisir et la joie de vivre ainsi que le goût pour l’exploration du vivant.
      </p>


      <p>
        En 1994 je me suis retrouvée en grande difficulté avec une crise aigu de sciatique, c’est pour régler cette problématique je me suis tournée vers
        <span class="highlight">
          la Méthode Feldenkrais<sup>TM</sup>
        </span>
        et grâce à trois séances d’Intégrations Fonctionnelles<sup>TM</sup> (volet individuel de la méthode) cette sciatalgie a été rapidement soulagée…
        <span class="highlight">
          J’ai découvert à cette occasion une qualité de toucher très particulière, que je n’avais rencontrée nulle part ailleurs.
        </span>
      </p>

      <p>
        Suite à cette expérience, j’ai commencé un travail en groupe (Prise de Conscience par le Mouvement<sup>TM</sup>) et j’ai été stupéfaite de la rapidité avec laquelle cette approche s'intégrait à mon quotidien :
        <span class="highlight">
          Au bout de quelques séances seulement, je sortais de mon lit avec légèreté, ma station assise était vraiment posée, je marchais avec plus de dynamisme et avec une conscience de moi dans l’espace très différente !
        </span>
      </p>

      <p>
        Après quatre années de pratique de la méthode, en questionnement sur mon orientation professionnelle, j’étais tentée de me former à ces approches qui m’avaient tant apportées, sans voir clairement le chemin que j’avais à prendre, jusqu’à ce qu’une certitude s’impose :  «  Ce que j’ai à transmettre, c'est la Méthode Feldenkrais ! » devant cette évidence, plus de questionnement !
      </p>

      <p>
        Je me suis formée auprès d’Accord Mobile avec Myriam Pfeffer en tant que directrice pédagogique.
      </p>

      <p>
        Au fil des années, je découvre et redécouvre sans cesse la profondeur de ce travail et l’ingéniosité de son fondateur Moshé Feldenkrais.
      </p>

      <p>
        J’affine au fil du temps les valeurs que j’ai envie de transmettre grâce à cet outil fabuleux :
        <span class="highlight">
          La qualité de la relation à soi, à l’autre, la valeur de l’instant présent...
        </span>
        Je cherche avec les personnes que j’accompagne à libérer le corps, afin de libérer l’esprit et la pépite qui se cache en chacun de nous.
      </p>

      <p>
        Je continue à me nourrir avec la danse, la méditation et d’autres approches qui viennent enrichir mon enseignement.
      </p>

    </div>
  </div>

  <div class="student-wrapper">
    <div class="student">
        <p class="quotationMark">&#8220;</p>
        <p class="text">
          "Cet Art du mouvement conscient amène progressivement le corps, geste après geste, comme goutte après goutte, à retrouver, à ré-emprunter, des chemins d'une mémoire enfouie – nerveuse, cellulaire?– et sans aucune contrainte, ni diktat que celui... du plaisir et de la douce voix de Sophie !
          Chapeau 'la passeuse du geste' !"
        </p>
        <p class="name">Alan F.</p>
    </div>
    <div class="student">
      <p class="quotationMark">&#8220;</p>
      <p class="text">
        "Je repars d'une séance complètement fluide et détendu, débloqué et baignant dans une sérénité impressionnante ! avec parfois la surprise de l'effet « ostéopathie-donnée-à-soi-même »."
      </p>
      <p class="name">Alan F.</p>
    </div>
    <div class="student">
      <p class="quotationMark">&#8220;</p>
      <p class="text">
        "Longtemps, j'ai cherché une activité respectueuse de mon corps, de mon esprit et de ma façon d'être.
        Mon dos n’a pas -encore!- arrêté de se plaindre mais... moi, j'ai arrêté de chercher !
        Je sens que je suis sur la bonne voie avec Sophie, sa manière de "passer" la Méthode Feldenkrais et les pouvoirs bénéfiques de sa voix."
      </p>
      <p class="name">Pilar</p>
    </div>
  </div>
</div>
