import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public innerWidth: any;

  logoSophie = '../../assets/logo.png';
  isLoggedIn: boolean = false;

  constructor(
  ) { }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged(userSession => {
      userSession ? this.isLoggedIn = true : this.isLoggedIn = false;
    });
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth);
  }

}
