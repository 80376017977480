import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.scss']
})
export class MethodComponent implements OnInit {

  logoSophie = '../../assets/logoHome.png';
  moshePicture = '../../assets/moshe.jpg';

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
