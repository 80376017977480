import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sophie-aubineau',
  templateUrl: './sophie-aubineau.component.html',
  styleUrls: ['./sophie-aubineau.component.scss']
})
export class SophieAubineauComponent implements OnInit {
  logoSophie = '../../assets/logoHome.png';
  imgSophie =  '../../assets/Sophie_Timeo.jpg';

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
