import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moshe',
  templateUrl: './moshe.component.html',
  styleUrls: ['./moshe.component.scss']
})
export class MosheComponent implements OnInit {

  logoSophie = '../../assets/logoHome.png';
  moshePortrait = '../../assets/moshe_portrait.png';
  mosheEnfant = '../../assets/moshe_enfant.png';

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
