<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Tous les stages</h2>
      <hr/>
    </div>

    <div>
      <div *ngFor="let event of events.slice().reverse(), let i = index, let last = last" class="events-card">

        <div class="title-ville-events">
          <div class="events-title" *ngIf="event.title">
            {{ event.title}}
          </div>
          <div class="events-ville" *ngIf="event.ville">
            {{ event.ville }}
          </div>
        </div>
        <div class="events-text" *ngIf="event.description" [innerHTML]="event.description">
          {{event.description}}
        </div>
        <div class="tarif-date-lieu">
          <div class="events-date-lieu">
            <div class="events.date" *ngIf="event.date">
              {{ event.date }}
            </div>
            <div class="events-lieu" *ngIf="event.lieu">
              {{event.lieu}}
            </div>
          </div>
          <div class="events-tarif" *ngIf="event.tarif">
            {{event.tarif}}
          </div>
        </div>

        <hr *ngIf="!last"/>
      </div>
    </div>

  </div>
</div>
