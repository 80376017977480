import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  logoSophie = '../../assets/logoHome.png';

  news: any[] = [];
  newsSubscription: Subscription;

  constructor(
    private newsService: NewsService,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.subscribeNews();
    this.newsService.getNews();
  }

  ngOnDestroy(): void {
    this.newsSubscription.unsubscribe();
  }

    // READ
    subscribeNews() {
      this.newsSubscription = this.newsService.newsSubject.subscribe(
        (news: any[]) => {
          this.news = news;
        }
      );
    }

}
