import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, OnDestroy {
  logoSophie = '../../assets/logoHome.png';

  events: any[] = [];
  eventsSubscription: Subscription;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.subscribeEvents();
    this.eventsService.getEvents();
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

    // READ :
    subscribeEvents() {
      this.eventsSubscription = this.eventsService.eventsSubject.subscribe(
        (events: any[]) => {
          this.events = events;
        }
      );
    }

}
