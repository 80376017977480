<div class="footerContent">
  <div class="footerMain">

    <a href="http://www.feldenkrais-france.org/" target="_blank" rel="noopener noreferrer">
      <img class="feldenkraisLogo" [src]='feldenkraisLogo' alt="logo Feldenkrais">
    </a>

    <div class="contact">
      <p>
        Praticienne certifiée de la méthode feldenkrais<sup>TM</sup>.
      </p>
      <p>
        Téléphone : 06 30 134 109
      </p>
      <p>
        Courriel : sophie.aubineau.feld@protonmail.com
      </p>
    </div>


    <button class="btn btn-link" routerLink="/admin" routerLinkActive="active" *ngIf="!isLoggedIn">
      <clr-icon shape="bug" class="is-solid"></clr-icon>
    </button>
    <button class="btn btn-link" (click)="onSignOut()" *ngIf="isLoggedIn">
      <clr-icon shape="logout" class="is-solid"></clr-icon>
    </button>
  </div>


  <div class="legal-mentions">
    <p>
      "Feldenkrais", "Méthode Feldenkrais" "Prise de concience par le mouvement", "Intégration Fonctionnelle" sont des termes déposés. Leur emploi n'est autorisé qu'aux personnes ayant suivi une formation professionnelle à la Méthode Feldenkrais dans des programmes accrédités.
    </p>
    <p>
      Crédits des photos Feldenkrais : ©internationale Feldenkrais® Archive Fédération, Robert Golden, ©internationale Feldenkrais® Archive Fédération et Feldenkrais France.
    </p>
    <p>
      <a href="https://www.instagram.com/aubiphoto/?hl=fr" class="link2" target="_blank" rel="noopener" rel="noreferrer">
        <img class="instagram_logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/langfr-220px-Instagram_logo_2016.svg.png" alt="logo instagram">
        Photo de Ingrid Aubineau
      </a>
      et
      <a href="https://www.linkedin.com/in/leagodard/" class="link2" target="_blank" rel="noopener" rel="noreferrer">
        site crée par Léa Godard.
      </a>
    </p>
  </div>

</div>
