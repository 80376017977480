<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="card card-img">
      <img [src]="moshePicture" alt="Photo de moshé feldenkrais" />
    </div>
    <div>
      <p>
        <span class="highlight">Moshé Feldenkrais</span> est né en 1904 en
        Russie.<br />
        Adolescent, il part en Palestine ou il restera une dizaine d’année.<span
          class="highlight"
        >
          Sportif de haut niveau, il y apprendra et enseignera le Jui Jitsu </span
        >, technique d'autodéfense.
      </p>
      <p>
        Dans les années trente, il vient s'installer en France pour étudier,
        après ses études d’ingénieur
        <span class="highlight"
          >il travaille sous la direction de Frédéric Joliot-Curie et Irène
          Curie (prix Nobel de Chimie en 1935), et de Paul Langevin. Il devient
          docteur en Sciences Physiques.</span
        >
      </p>
      <p>
        C’est à Paris qu’il<span class="highlight">
          rencontrera Jigoro kano, fondateur du judo</span
        >, il obtiendra l'une des premières ceintures noires de judo en Europe
        et participera à la création du premier club de Ju Jitsu de Paris.
      </p>
      <p>
        C’est dans les années quarante qu'un de ses genoux plusieurs fois blessé
        lui pose de graves problèmes ; la chirurgie de l'époque n'étant pas
        prometteuse,
        <span class="highlight"
          >il va étudier avec sa rigueur de scientifique, le corps d'un point de
          vue biomécanique</span
        >
        et développer sa connaissance du mouvement déjà acquise à travers le
        judo.
      </p>
      <p>
        <span class="highlight"
          >Les arts martiaux lui ont appris l'utilisation d'un minimum d'effort
          pour un maximum d'efficacité.</span
        >
        Il va s'intéresser à l'évolution de l'espèce et grâce à son épouse
        pédiatre,
        <span class="highlight">au développement moteur de l'enfant</span> ainsi
        qu'à
        <span class="highlight"
          >des travaux de précurseurs tels que Giurdjieff ou Mathias
          Alexander.</span
        ><br />
        Dans un deuxième temps
        <span class="highlight">il va étudier les neurosciences</span>,
        balbutiantes à l’époque mais qui vont lui ouvrir tout un champ de
        possibles en présentant ce qui est confirmé aujourd’hui :
        <span class="highlight"
          >la neuroplasticité et la capacité du cerveau à apprendre tout au long
          de la vie.</span
        >
      </p>
      <p>
        <span class="highlight"
          >Des techniques comme le yoga ou le zen attirent aussi son
          attention.</span
        >
      </p>
      <p>
        A travers ces études approfondies, il observe que sa capacité à bouger
        sans douleur dépend grandement de sa façon d’agir, et que celle-ci peut
        être réajustée grâce à un processus simple.
      </p>
      <p>
        <span class="highlight"
          >Il va récupérer l'usage de son genoux , de cette expérience va naître
          sa Méthode</span
        >. Il va consacrer les dernières années de sa vie à la développer et à
        l’approfondir.
        <span class="highlight"
          >Ses travaux sont considérés encore aujourd'hui, comme précurseurs
          dans de nombreux domaines : éducation, santé, arts...</span
        >
      </p>
      <p>
        Il enseignera en Europe, en Israël, en Australie et aux Etats-Unis et
        formera des praticiens, capables à leur tour de transmettre sa méthode
        et de poursuivent ses recherches.
      </p>
    </div>
  </div>
</div>
