<div class="backgroundPage">
  <div class="light-bg">
    <img class="headerLogo" [src]='logoSophie' routerLink="/accueil" routerLinkActive="active" alt="logo Sophie Aubineau">
  </div>
</div>
<div class="webContent">
  <app-header></app-header>
  <div class="pageContent">
    <div class="pageTitle">
      <h2>Toutes les Actualités</h2>
      <hr/>
    </div>
    <div>
      <div *ngFor="let new of news.slice().reverse(), let i = index, let last = last" class="news-card">

        <div class="title-date-news">
          <div class="news-title" *ngIf="new.title">
            {{ new.title}}
          </div>
          <div class="news-date" *ngIf="new.date">
            {{ new.date }}
          </div>
        </div>
        <div class="news-text" *ngIf="new.description" [innerHTML]="new.description">
          {{new.description}}
        </div>

        <hr *ngIf="!last"/>
      </div>
    </div>

  </div>

  <div class="student-wrapper">
    <div class="student">
        <p class="quotationMark">&#8220;</p>
        <p class="text">
          "La méthode Feldenkrais m'a beaucoup apporté, c’est tout d'abord un apprentissage du mouvement qui permet de bouger son corps avec plus de liberté et de bien être.
          D'autre part, en redonnant du mouvement dans des zones bloquées, une nouvelle image de moi a surgit, image positive et porteuse d'énergie, de renouvellement."
        </p>
        <p class="name">Mathieu</p>
    </div>
  </div>
</div>
